@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600;700&family=Bebas+Neue&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: none;
}
@media screen and (max-width: 768px) {
  * {
    cursor: auto;
  }
}

::-moz-selection {
  background: #6a7079;
  color: white;
}

::selection {
  background: #6a7079;
  color: white;
}

html {
  scroll-behavior: smooth;
  font-size: 16px;
}

body {
  font-family: "Raleway", sans-serif;
  background-color: #0a0a0a;
  color: #ffffff;
  overflow-x: hidden;
  line-height: 1.6;
}

a {
  text-decoration: none;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
  cursor: none;
}
a:hover {
  color: #6a7079;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  padding-bottom: 15px;
  text-align: center;
  margin-bottom: 3rem;
}
h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #6a7079;
}
h2 .accent {
  color: #6a7079;
}

section {
  padding: 100px 5%;
  position: relative;
  overflow: hidden;
}
section:nth-child(odd) {
  background-color: rgba(20, 20, 20, 0.6);
}

.accent {
  color: #6a7079;
}

.custom-cursor {
  position: fixed;
  width: 40px;
  height: 40px;
  border: 2px solid white;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease;
  z-index: 9999;
  mix-blend-mode: difference;
}
@media screen and (max-width: 768px) {
  .custom-cursor {
    display: none;
  }
}

.cursor-dot {
  position: fixed;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  z-index: 10000;
  transition: transform 0.05s ease;
}
@media screen and (max-width: 768px) {
  .cursor-dot {
    display: none;
  }
}

.particle-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.main-nav {
  position: fixed;
  display: flex;
  top: -2px;
  left: 0;
  width: 100%;
  padding: 15px 5%;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease, backdropFilter 0.3s ease, box-shadow 0.3s ease;
}
.main-nav .logo {
  font-family: "Bebas Neue", sans-serif;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 2px;
  align-items: center;
  justify-items: center;
  display: flex;
}
.main-nav .logo .logo-img {
  width: 3.5rem;
  margin-right: 10px;
  align-items: center;
}
.main-nav .logo span.accent {
  color: #6a7079;
}
.main-nav .desktop-nav ul {
  display: flex;
  list-style: none;
}
.main-nav .desktop-nav ul li {
  margin-left: 30px;
  position: relative;
}
.main-nav .desktop-nav ul li a {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  position: relative;
  padding: 5px 0;
}
.main-nav .desktop-nav ul li a .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #6a7079;
}
.main-nav .desktop-nav ul li.active a {
  color: #6a7079;
}
.main-nav .hamburger {
  display: none;
}
.main-nav .hamburger input {
  display: none;
}
.main-nav .hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 3em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.main-nav .line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.main-nav .line-top-bottom {
  stroke-dasharray: 12 63;
}
.main-nav .hamburger input:checked + svg {
  transform: rotate(-45deg);
}
.main-nav .hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}

.hero-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 70px;
  position: relative;
  overflow: hidden;
}
.hero-section .hero-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.hero-section .hero-video-container .hero-video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.hero-section .hero-video-container .hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0.6) 100%);
}
.hero-section .hero-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
}
.hero-section .hero-content h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1.2;
  font-weight: 700;
}
.hero-section .hero-content h1 span {
  display: block;
  font-size: 4.5rem;
}
.hero-section .hero-content .tagline {
  font-size: 1.5rem;
  font-style: italic;
  margin-bottom: 2rem;
  color: #ffffff;
  font-weight: 300;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4941176471);
}
.hero-section .hero-content .description {
  font-size: 1.1rem;
  max-width: 700px;
  margin: 0 auto 3rem;
  opacity: 0.9;
}
.hero-section .hero-content .cta-button {
  background-color: #6a7079;
  color: #fff;
  border: none;
  padding: 15px 35px;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}
.hero-section .hero-content .cta-button:hover {
  background-color: #52575e;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.about-section .about-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 50px;
}
.about-section .about-content .about-image {
  flex: 1;
  min-width: 300px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.4);
}
.about-section .about-content .about-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.6s ease;
}
.about-section .about-content .about-image .image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
}
.about-section .about-content .about-image:hover img {
  transform: scale(1.05);
}
.about-section .about-content .about-text {
  flex: 1;
  min-width: 300px;
}
.about-section .about-content .about-text p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: justify;
  text-shadow: 2px 2px 4px #535353;
}
.about-section .about-content .about-text p:last-child {
  margin-bottom: 0;
}

.gallery-section {
  justify-content: center;
  align-items: center;
}
.gallery-section .gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
@media screen and (max-width: 600px) {
  .gallery-section .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}
.gallery-section .gallery-container .gallery-item {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  aspect-ratio: 1/1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.gallery-section .gallery-container .gallery-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 0.5s ease;
}
.gallery-section .gallery-container .gallery-item .gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.gallery-section .gallery-container .gallery-item .gallery-overlay span {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 2px solid #6a7079;
  padding: 10px 20px;
  border-radius: 5px;
  transform: translateY(20px);
  transition: all 0.3s ease;
}
.gallery-section .gallery-container .gallery-item .gallery-overlay span:hover {
  background-color: rgba(106, 112, 121, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.6);
}
.gallery-section .gallery-container .gallery-item:hover {
  transform: translateY(-5px);
}
.gallery-section .gallery-container .gallery-item:hover img {
  transform: scale(1.1);
}
.gallery-section .gallery-container .gallery-item:hover .gallery-overlay {
  opacity: 1;
}
.gallery-section .gallery-container .gallery-item:hover .gallery-overlay span {
  transform: translateY(0);
}
.gallery-section .fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.gallery-section .fullscreen-overlay .fullscreen-content {
  max-width: 60%;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
@media screen and (max-width: 768px) {
  .gallery-section .fullscreen-overlay .fullscreen-content {
    max-width: 90%;
  }
}
.gallery-section .fullscreen-overlay .fullscreen-content .fullscreen-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: auto;
}
.gallery-section .fullscreen-overlay .fullscreen-content .close-button {
  position: absolute;
  top: 30px;
  right: 30px;
  background: white;
  border: none;
  font-size: 24px;
  padding: 4px 10px;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}
.gallery-section .fullscreen-overlay .fullscreen-content .close-button:hover {
  background-color: #6a7079;
  color: #ffffff;
}
.gallery-section .show-more-button {
  background-color: transparent;
  border: 1px solid white;
  display: block;
  margin: 4rem auto 0;
  color: #fff;
  padding: 15px 35px;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 5px;
  transition: all 0.5s cubic-bezier(0.25, 1, 0.5, 1);
}
.gallery-section .show-more-button:hover {
  background-color: #52575e;
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.achievements-section .timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}
.achievements-section .timeline::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #6a7079;
  left: 50%;
  transform: translateX(-50%);
}
.achievements-section .timeline .timeline-item {
  position: relative;
  width: 50%;
  padding: 20px 40px;
  margin-bottom: 25px;
  box-sizing: border-box;
}
.achievements-section .timeline .timeline-item:nth-child(odd) {
  left: 0;
  padding-right: 50px;
}
.achievements-section .timeline .timeline-item:nth-child(odd) .timeline-content {
  text-align: right;
  border-radius: 20px 0 20px 20px;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background: rgba(197, 197, 197, 0.1254901961);
  box-shadow: 0 0 6px 4px rgba(197, 197, 197, 0.1254901961);
}
.achievements-section .timeline .timeline-item:nth-child(odd) .timeline-content::after {
  content: "";
  position: absolute;
  top: 20px;
  right: -10px;
  width: 20px;
  height: 20px;
  background: #6a7079;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(156, 156, 156, 0.5019607843);
}
.achievements-section .timeline .timeline-item:nth-child(even) {
  left: 50%;
  padding-left: 50px;
}
.achievements-section .timeline .timeline-item:nth-child(even) .timeline-content {
  border-radius: 0 20px 20px 20px;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  background: rgba(197, 197, 197, 0.1254901961);
  box-shadow: 0 0 6px 4px rgba(197, 197, 197, 0.1254901961);
}
.achievements-section .timeline .timeline-item:nth-child(even) .timeline-content::after {
  content: "";
  position: absolute;
  top: 20px;
  left: -10px;
  width: 20px;
  height: 20px;
  background: #6a7079;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(156, 156, 156, 0.5019607843);
}
.achievements-section .timeline .timeline-item .timeline-content {
  position: relative;
  padding: 30px;
  background: rgba(30, 30, 30, 0.8);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.achievements-section .timeline .timeline-item .timeline-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 15px 3px rgba(212, 212, 212, 0.4);
}
.achievements-section .timeline .timeline-item .timeline-content .year {
  display: inline-block;
  padding: 5px 15px;
  background: #6a7079;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  margin-bottom: 15px;
  font-size: 1.1rem;
}
.achievements-section .timeline .timeline-item .timeline-content .title {
  font-size: 1.15rem;
  line-height: 1.5;
}
.achievements-section .script {
  text-align: center;
}

.registration-section {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(10, 10, 10, 0.9) 100%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.registration-section .form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px;
  background: rgba(20, 20, 20, 0.8);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  border-radius: 15px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.registration-section .form-container .form-intro {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.8);
}
.registration-section .form-container .form-group {
  position: relative;
  margin-bottom: 30px;
}
.registration-section .form-container .form-group label {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  pointer-events: none;
}
.registration-section .form-container .form-group input {
  width: 100%;
  padding: 15px 10px;
  font-size: 1rem;
  color: white;
  background: transparent;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  outline: none;
  transition: border-color 0.3s ease;
}
.registration-section .form-container .form-group input:focus {
  border-color: #6a7079;
}
.registration-section .form-container .form-group .input-highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  background-color: #6a7079;
  transition: width 0.3s ease;
}
.registration-section .form-container .form-group.active label {
  transform: translateY(-25px) scale(0.9);
  color: #6a7079;
}
.registration-section .form-container .form-group.active input {
  border-color: #6a7079;
}
.registration-section .form-container .form-group.active .input-highlight {
  width: 100%;
}
.registration-section .form-container .submit-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-top: 40px;
  background-color: #6a7079;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: all 0.3s ease;
}
.registration-section .form-container .submit-button:hover {
  background-color: #52575e;
  box-shadow: 0 0 20px rgba(106, 112, 121, 0.5);
}
.registration-section .form-container .status-message {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}
.registration-section .form-container .status-message.success {
  background-color: #003413;
  color: white;
}
.registration-section .form-container .status-message.duplicate {
  background-color: #5f5501;
  color: white;
}
.registration-section .form-container .status-message.rejected {
  background-color: #7b4400;
  color: white;
}
.registration-section .form-container .status-message.error {
  background-color: #7b0000;
  color: white;
}
.registration-section .form-container .status-message .message-text {
  margin-bottom: 12px;
}
.registration-section .form-container .status-message .button-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}
.registration-section .form-container .status-message .button-container .btn {
  padding: 8px 16px;
  border-radius: 5px;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}
.registration-section .form-container .status-message .button-container .btn-yes {
  background-color: #007029;
}
.registration-section .form-container .status-message .button-container .btn-yes:hover {
  background-color: #006425;
}
.registration-section .form-container .status-message .button-container .btn-no {
  background-color: #7b0000;
}
.registration-section .form-container .status-message .button-container .btn-no:hover {
  background-color: #5f0000;
}
.registration-section .form-container .form-note {
  text-align: center;
  margin-top: 20px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  font-style: italic;
}

.footer {
  background-color: #0a0a0a;
  padding: 70px 5% 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .footer-content {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  margin-bottom: 50px;
}
.footer .footer-content .footer-bio {
  flex: 1;
  min-width: 300px;
  justify-content: center;
}
.footer .footer-content .footer-bio h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
}
.footer .footer-content .footer-bio h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #6a7079;
}
.footer .footer-content .footer-bio p {
  margin-bottom: 20px;
  line-height: 1.6;
  text-align: justify;
}
.footer .footer-content .footer-bio .coach-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #6a7079;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}
.footer .footer-content .footer-bio .coach-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer .footer-content .footer-social {
  flex: 1;
  min-width: 300px;
}
.footer .footer-content .footer-social h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  position: relative;
  padding-bottom: 10px;
}
.footer .footer-content .footer-social h3::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 2px;
  background-color: #6a7079;
}
.footer .footer-content .footer-social .social-links {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.footer .footer-content .footer-social .social-links a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1rem;
}
.footer .footer-content .footer-social .social-links a i {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}
.footer .footer-content .footer-social .social-links a:hover {
  color: #6a7079;
}
.footer .footer-content .footer-social .social-links a:hover i {
  background-color: #6a7079;
  color: white;
  transform: scale(1.1);
}
.footer .footer-copyright {
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .footer-copyright p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-width: 992px) {
  html {
    font-size: 14px;
  }
  .hero-section .hero-content h1 {
    font-size: 3.5rem;
  }
  .hero-section .hero-content h1 span {
    font-size: 4rem;
  }
  .achievements-section .timeline::before {
    left: 40px;
  }
  .achievements-section .timeline .timeline-item {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
  }
  .achievements-section .timeline .timeline-item:nth-child(odd) {
    left: 0;
  }
  .achievements-section .timeline .timeline-item:nth-child(odd) .timeline-content {
    text-align: left;
    border-radius: 0 20px 20px 20px;
  }
  .achievements-section .timeline .timeline-item:nth-child(odd) .timeline-content::after {
    left: -10px;
    right: auto;
  }
  .achievements-section .timeline .timeline-item:nth-child(even) {
    left: 0;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 13px;
  }
  .desktop-nav {
    display: none;
  }
  .layer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    top: -1.1rem;
    left: 0;
  }
  .main-nav {
    padding: 15px 5%;
  }
  .main-nav .nav {
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.6);
    height: 100vh;
    width: 60%;
    top: 0;
    right: 0;
    z-index: 2000;
    box-shadow: 0 2px 4px rgba(2, 15, 29, 0.9);
    transition: transform 0.5s ease-in-out;
    transform: translateX(100%);
  }
  .main-nav .nav ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 3rem 0;
    width: 100%;
    margin: auto;
  }
  .main-nav .nav ul li {
    margin-left: 0;
    padding: 0.5rem 0;
    position: relative;
    text-align: center;
  }
  .main-nav .nav ul li a {
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    position: relative;
    padding: 5px 0;
  }
  .main-nav .nav ul li a .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #6a7079;
  }
}
@media screen and (max-width: 768px) and (max-width: 340px) {
  .main-nav .nav ul li a {
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .main-nav .nav ul li.active a {
    color: #6a7079;
  }
}
@media screen and (max-width: 768px) {
  .main-nav .activeNav {
    right: 0;
    transform: translateX(0);
    z-index: 100;
  }
}
@media screen and (max-width: 768px) {
  .main-nav .hamburger {
    display: block;
    z-index: 1000;
  }
  .main-nav .hamburger input {
    padding: 3rem;
  }
}
@media screen and (max-width: 768px) {
  .hero-section .hero-content h1 {
    font-size: 3rem;
  }
  .hero-section .hero-content h1 span {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 768px) {
  section {
    padding: 70px 5%;
  }
}
@-webkit-keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(106, 112, 121, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(106, 112, 121, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(106, 112, 121, 0);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(106, 112, 121, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(106, 112, 121, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(106, 112, 121, 0);
  }
}
.glitch-effect {
  position: relative;
}
.glitch-effect::before, .glitch-effect::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0a0a0a;
}
.glitch-effect::before {
  left: 2px;
  text-shadow: -2px 0 #6a7079;
  clip: rect(24px, 550px, 90px, 0);
  animation: glitch-anim 3s infinite linear alternate-reverse;
}
.glitch-effect::after {
  left: -2px;
  text-shadow: -2px 0 #00ffff;
  clip: rect(85px, 550px, 140px, 0);
  animation: glitch-anim 2.5s 0.5s infinite linear alternate-reverse;
}

@-webkit-keyframes glitch-anim {
  0% {
    clip: rect(11px, 9999px, 10px, 0);
  }
  5% {
    clip: rect(44px, 9999px, 96px, 0);
  }
  10% {
    clip: rect(54px, 9999px, 98px, 0);
  }
  15% {
    clip: rect(95px, 9999px, 59px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 98px, 0);
  }
  25% {
    clip: rect(40px, 9999px, 73px, 0);
  }
  30% {
    clip: rect(6px, 9999px, 12px, 0);
  }
  35% {
    clip: rect(25px, 9999px, 61px, 0);
  }
  40% {
    clip: rect(24px, 9999px, 26px, 0);
  }
  45% {
    clip: rect(76px, 9999px, 97px, 0);
  }
  50% {
    clip: rect(46px, 9999px, 15px, 0);
  }
  55% {
    clip: rect(72px, 9999px, 92px, 0);
  }
  60% {
    clip: rect(23px, 9999px, 66px, 0);
  }
  65% {
    clip: rect(58px, 9999px, 30px, 0);
  }
  70% {
    clip: rect(57px, 9999px, 99px, 0);
  }
  75% {
    clip: rect(39px, 9999px, 12px, 0);
  }
  80% {
    clip: rect(23px, 9999px, 77px, 0);
  }
  85% {
    clip: rect(56px, 9999px, 23px, 0);
  }
  90% {
    clip: rect(81px, 9999px, 43px, 0);
  }
  95% {
    clip: rect(69px, 9999px, 16px, 0);
  }
  100% {
    clip: rect(76px, 9999px, 71px, 0);
  }
}

@keyframes glitch-anim {
  0% {
    clip: rect(11px, 9999px, 10px, 0);
  }
  5% {
    clip: rect(44px, 9999px, 96px, 0);
  }
  10% {
    clip: rect(54px, 9999px, 98px, 0);
  }
  15% {
    clip: rect(95px, 9999px, 59px, 0);
  }
  20% {
    clip: rect(91px, 9999px, 98px, 0);
  }
  25% {
    clip: rect(40px, 9999px, 73px, 0);
  }
  30% {
    clip: rect(6px, 9999px, 12px, 0);
  }
  35% {
    clip: rect(25px, 9999px, 61px, 0);
  }
  40% {
    clip: rect(24px, 9999px, 26px, 0);
  }
  45% {
    clip: rect(76px, 9999px, 97px, 0);
  }
  50% {
    clip: rect(46px, 9999px, 15px, 0);
  }
  55% {
    clip: rect(72px, 9999px, 92px, 0);
  }
  60% {
    clip: rect(23px, 9999px, 66px, 0);
  }
  65% {
    clip: rect(58px, 9999px, 30px, 0);
  }
  70% {
    clip: rect(57px, 9999px, 99px, 0);
  }
  75% {
    clip: rect(39px, 9999px, 12px, 0);
  }
  80% {
    clip: rect(23px, 9999px, 77px, 0);
  }
  85% {
    clip: rect(56px, 9999px, 23px, 0);
  }
  90% {
    clip: rect(81px, 9999px, 43px, 0);
  }
  95% {
    clip: rect(69px, 9999px, 16px, 0);
  }
  100% {
    clip: rect(76px, 9999px, 71px, 0);
  }
}
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.splash-screen .splash-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.splash-screen .splash-content .splash-logo {
  margin-bottom: 50px;
  text-align: center;
}
.splash-screen .splash-content .splash-logo .logo-text {
  font-family: "Bebas Neue", sans-serif;
  font-size: 5rem;
  letter-spacing: 5px;
  display: inline-block;
}
.splash-screen .splash-content .splash-logo .logo-text.accent {
  color: #6a7079;
  margin-left: 10px;
}
.splash-screen .splash-content .progress-container {
  width: 300px;
}
.splash-screen .splash-content .progress-container .progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}
.splash-screen .splash-content .progress-container .progress-bar-container .progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #6a7079, #9fa3aa);
  border-radius: 5px;
}
.splash-screen .splash-content .progress-container .progress-text {
  text-align: right;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
}
/*# sourceMappingURL=App.css.map */